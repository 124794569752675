import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {
  "Browser API": "a0578d77efad",
  BOM: "a0578d77efad",
  Navigator: "a0578d77efad",
  History: "a0578d77efad",
  Location: "a0578d77efad",
  Console: "a0578d77efad",
  iframe: "a0578d77efad",
  Window: "a0578d77efad",
  Screen: "a0578d77efad",
  Storage: "a0578d77efad",

  DOM: "cf1e69e615b",
  SVG: "cf1e69e615b",
  HTMLElements: "cf1e69e615b",
  "Static DOM": "cf1e69e615b",
  "DOM API": "cf1e69e615b",
  "JS-innerHTML": "cf1e69e615b",
  "JQuery-html": "cf1e69e615b",

  URL: "4788ab650fe3",
  Protocol: "4788ab650fe3",
  "Domain Name": "4788ab650fe3",
  Port: "4788ab650fe3",
  Path: "4788ab650fe3",
  "Search Parameters": "4788ab650fe3",
  Anchor: "4788ab650fe3",

  "Web Events": "7972fd5787ec",
  "Event Target": "7972fd5787ec",
  "Event Capturing": "7972fd5787ec",
  "Event Bubbling": "7972fd5787ec",

  DnD: "781c4f456426",

  "Console.log": "2f1d352e969e",
  "Log Level": "2f1d352e969e",
  "Log Formatting": "2f1d352e969e",
  "Log Debugging": "2f1d352e969e",
  "Log Performance": "2f1d352e969e",

  sendBeacon: "1d2f693c7125",
  ArrayBufferView: "1d2f693c7125",
  Blob: "1d2f693c7125",
  DOMString: "1d2f693c7125",
  FormData: "1d2f693c7125",

  "Web Worker": "a0db7a8c71b7",
  "Shared Worker": "a0db7a8c71b7",
  "Service Workers": "a0db7a8c71b7",
  PWA: "a0db7a8c71b7",
  Worklets: "a0db7a8c71b7",

  Installable: "35e4f99c7bbf",
  Offline: "35e4f99c7bbf",
  Responsive: "35e4f99c7bbf",
  Fast: "35e4f99c7bbf",
  "Web Push Notification": "35e4f99c7bbf",
  "Home Screen": "35e4f99c7bbf",
  "Splash Screen": "35e4f99c7bbf",
  Geolocation: "35e4f99c7bbf",
  "Video/Image Capture": "35e4f99c7bbf",

  "High-Resolution Time": "a169cad832c8",
  "Performance Timeline": "a169cad832c8",
  "Resource Timing": "a169cad832c8",
  "Navigation Timing": "a169cad832c8",
  "User Timing": "a169cad832c8",
  "Server Timing": "a169cad832c8",
  FrameTiming: "a169cad832c8",

  "Fetch API-1": "7ccf2150aa1",
  "Fetch API-2": "9d195aec412",
}

const stories = [
  {
    title: "Browser API Nedir?",
    postId: "a0578d77efad",
  },
  {
    title: "Web DOM API Kullanımı?",
    postId: "cf1e69e615b",
  },
  {
    title: "Fetch API Pratikleri -1",
    postId: "7ccf2150aa1",
  },
  {
    title: "Fetch API Pratikleri -2",
    postId: "9d195aec412",
  },
  {
    title: "Web SVG API Kullanımı?",
    postId: "",
    enabled: false,
  },
  {
    title: "Web Canvas API Kullanımı?",
    postId: "",
    enabled: false,
  },
  {
    title: "Web URL API Kullanımı?",
    postId: "4788ab650fe3",
  },
  {
    title: "Web Events",
    postId: "7972fd5787ec",
  },
  {
    title: "Web Events (Drag-Drop)",
    postId: "781c4f456426",
  },
  {
    title: "Console API",
    postId: "2f1d352e969e",
  },
  {
    title: "WebGL / Web Audio / Web Speech API Kullanımı",
    postId: "",
    enabled: false,
  },
  {
    title: "Device API Kullanımı",
    postId: "",
    enabled: false,
  },
  {
    title: "Beacon API Kullanımı",
    postId: "1d2f693c7125",
  },
  {
    title: "Data management API Kullanımı",
    postId: "",
    enabled: false,
  },
  {
    title: "Worker API",
    postId: "a0db7a8c71b7",
  },
  {
    title: "Service Worker-1 (PWA Nedir)",
    postId: "35e4f99c7bbf",
  },
  {
    title: "Performance API Kullanımı",
    postId: "a169cad832c8",
  },
  {
    title: "iFrame Nedir ? Nerelerde Kullanılır ?",
    postId: "5cf58d66ab77",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Web API Kullanımı",
  path: "web-api-kullanimi",
}

const WEBAPIUsagePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default WEBAPIUsagePage
